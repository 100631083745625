@media screen and (max-width: 1440px) {

    .contact-main {
        margin: 0 15rem;
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    .navbar>h2 {
        font-size: 2rem !important;
    }

    .animated-text {
        font-size: 72px;
    }


    /* Showcase */
    .showcase-outer {
        padding: 0 5rem;

    }

    .showcase-smalltitle {
        width: 14rem;
    }

    .showcase-bigtitle {
        width: 70%;
        font-size: 20px;
    }

    /* SERVICES */
    .offers {
        padding: 0 10rem;
    }

    .services-inner {
        padding: 0;
    }

    /* ABOUT US */
    .about-c-1 {
        padding: 0rem 0rem;
    }

    /* INTERIOR */
    .interior-section {
        padding: 5rem 5rem;
    }

    /* NAVBAR */
    .map-iframe {
        width: 500px;
    }

    .footer-contact {
        width: 40%;
    }

    .footer-disclaimer {
        margin-left: 5rem;
    }

    /* MENU */


    .loading-screen {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
}


@media screen and (max-width: 1024px) {

    .contact-main {
        margin: 0 5rem;
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    .navbar-item {
        margin-left: 1.5rem;
        font-size: 18px;
    }

    .navbar {
        padding-right: 6rem;
    }

    .navbar>h2 {
        font-size: 28px !important;
    }

    /* SHOWCASE */
    .hide-1024 {
        display: none;
    }

    .showcase-bigtitle {
        width: 70%;
        font-size: 18px;
    }

    /* SERVICES */
    .offers {
        padding: 0 3rem;
    }

    .offers-inner>div>img {
        width: 3.5rem;
    }

    .offers-inner>div>p {
        font-size: 18px;
    }

    .tm-o {
        margin-top: 5rem;
    }

    /* ABOUT */
    .about-section {
        padding: 1rem 0rem;

    }

    .about-upper {
        width: 100%;
        top: 2rem;
        padding: 0;
    }

    .about-upper>div {
        margin: 0 5rem;
    }

    .about-upper>div>p {
        width: 100%;
        font-size: 20px;
    }

    .about-main-cont {
        flex-direction: column;
    }

    .about-container {
        justify-content: start;
        height: fit-content;
        height: 75% !important;
    }

    .about-first {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 0;
        padding-top: 2rem;
        height: fit-content;
    }

    .about-second {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
        padding-top: 1rem;

    }


    /* FOOTER */
    .footer-contact {
        display: none;
    }

    .footer-links {
        width: 100%;
        justify-content: space-between;
    }

    .map-iframe {
        width: 250px;
        height: 250px;
    }

    .footer-contact-info>p {
        font-size: 36px;
    }


    /* MENU */
    .menu-inner {
        padding: 5rem 5rem;
    }
}


@media screen and (max-width: 768px) {

    .contact-main {
        margin: 0 2rem;
        margin-top: 10rem;
        margin-bottom: 10rem;
    }

    .c-main-inner {
        flex-direction: column;
        margin-bottom: 4rem;
    }

    .c-main-inner>p {
        margin-top: 1rem;
    }

    .navbar-right {
        flex-direction: column;
    }

    .navbar-item.phone {
        align-self: flex-end;
        order: 2;
    }

    .navbar-item {
        margin-left: 0;
    }

    .navbar>h2 {
        font-size: 22px !important;
    }

    /* SHOWCASE */
    .showcase-inner {
        flex-direction: column;
    }

    .showcase-bigtitle {
        width: 100%;
    }

    .showcase-outer {
        height: auto;
        padding: 3rem 5rem;
    }

    .scrolldown {
        display: none;
    }


    .see-more::before {
        content: "SCROLL DOWN & ";
    }

    .showcase-smalltitle {
        width: 100%;
    }

    .inner-fullpage-section {
        height: 45vh !important;
    }

    /* SERVICES */
    .services-inner,
    .ServicesPage {
        height: 100%;
        margin-bottom: 3rem;
    }

    .offers {
        height: auto;
    }

    .offers-inner {
        flex-wrap: wrap;
    }

    .offers-inner>div {
        width: 50%;
        margin-bottom: 3rem;
    }

    .testimonials-inner {
        flex-wrap: wrap;
        justify-content: center;
    }

    .testimonials-inner>div {
        margin-bottom: 4rem;
    }


    /* ABOUT */
    .about-upper {
        position: unset;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: unset;
        transform: unset;
    }

    .socials-box {
        align-items: center;
        justify-content: center;
    }

    .about-follow {
        margin-bottom: 2rem !important;
    }

    .about-section {
        flex-direction: column;
        height: auto;
    }

    .about-mini-bottom {
        margin: 0 !important;
    }

    .about-main-cont {
        margin-top: 3rem;
    }


    /* INTERIOR */
    .interior-upper {
        flex-direction: column;
    }

    .showcase-smalltitle {
        margin-top: 3rem;
    }

    .showcase-smalltitle>br {
        display: none;
    }

    .interior-bottom {
        height: 30rem;
    }

    /* FOOTER */
    .footer-section {
        height: auto;
    }

    .footer-upper {
        flex-direction: column;
    }

    .map-iframe {
        width: 100%;
        height: 250px;
        margin-top: 3rem;
    }

    .hr-cont {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

    .footer-links {
        flex-wrap: wrap;
    }


    /* MENU */
    .menu-inner {
        flex-direction: column;
    }

    .menu-second {
        margin-top: 5rem;
    }

    .full-page-menu {
        overflow-y: auto;
    }
}


@media screen and (max-width: 576px) {

    .l1,
    .l2 {
        font-size: 28px;
    }

    .loading-screen {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .navbar-right {
        display: none;
    }

    .animated-text {
        font-size: 50px;
    }

    /* SHOWCASE */
    .showcase-outer {
        padding: 0 3rem;
    }

    .interior-section {
        padding: 5rem 3rem;
    }

    .footer-socials {
        margin-right: 0;
    }

    .footer-disclaimer {
        margin-left: 0;
        margin-top: 5rem;

    }

    .hr-cont {
        justify-content: center;
    }

    .footer-hr {
        display: none;
    }


    /* MENU */
    .menu-link {
        font-size: 54px;
    }
}

@media screen and (max-width: 425px) {

    .l1,
    .l2 {
        font-size: 22px;
    }

    .hide-1024:nth-child(2) {
        display: unset;
    }

    /* MENU */
    .menu-inner {
        padding: 5rem 2rem;
    }

    .menu-link {
        font-size: 42px;
    }

    .menu-title {
        font-size: 22px;
    }

    /* ABOUT */
    .about-second {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 2rem;
        padding-top: 1rem;
    }

    .about-inner-img {
        border-radius: 0;
    }

    .about-upper {
        transform: unset;
    }

    .about-follow,
    .about-brief {
        width: 100% !important;
        margin: 0 !important;
        margin-bottom: 2rem !important;
    }

    /* SHOWCASE */
    .showcase-container {
        margin-bottom: 2rem;
        height: auto;
    }

    /* SERVICES */
    .tm-o {
        width: 80%;
    }

    .tm {
        width: 100%;
    }

    .offers-inner>div {
        width: 100%;
        margin-bottom: 3rem;
    }

    /* INTERIOR */
    .interior-section {
        padding: 5rem 2rem;
        height: auto;
    }


    /* FOOTER */
    .footer-section {
        padding: 5rem 2rem;
    }

    .footer-contact-info>p {
        font-size: 26px;
    }

    .hr-p {
        display: none;
    }

    .footer-hr:nth-child(1) {
        display: block;
        width: 100%;
    }

    .footer-links>div {
        width: 100%;
        margin-bottom: 3rem;
    }

    .footer-socials-inner {
        flex-wrap: wrap;
        width: 100%;
    }

    .footer-disclaimer {
        margin-top: 0;
    }

}

@media screen and (max-width: 375px) {

    .contact-main {
        margin: 0 0rem;
        margin-top: 10rem;
        margin-bottom: 10rem;
    }


    .l1,
    .l2 {
        font-size: 20px;
    }


    .animated-text {
        font-size: 30px;
    }

    .interior-bottom>img {
        object-position: 50% 0%;
    }

}