.fullpage-video {
    width: 100%;
    height: inherit;
    opacity: 0.5;
    position: absolute;
    top: 0;
    object-fit: cover;
    z-index: 1;
}

.navbar,
.landing-bottom {
    z-index: 5;
    user-select: none;
}

.landing {
    background-color: black;
    justify-content: space-between;
}

.navbar {
    justify-content: space-between;
    padding: .5rem 1.5rem;
    padding-right: 8rem;
}

.navbar-item {
    margin-left: 5rem;
    font-size: 22px;
}

.phone,
.mail {
    text-decoration: none;
    color: #efefef;
}

.menu {
    border-radius: 5rem;
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    position: relative;
    cursor: pointer;
}

.menu>img {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navbar>h2 {
    font-weight: 400;
    margin: 0;
    font-size: 3rem;
}

.navbar-right {
    align-items: center;
}

.brand-first {
    font-weight: 600;
}

.landing-bottom {
    justify-content: space-between;
    align-items: center;
    align-self: center;
}

.landing-brief {
    width: 40rem;
    padding: 1rem;
    font-size: 18px;
    margin-bottom: 10rem;
    text-align: center;
}

.animated-text {
    justify-content: center;
    text-align: center;
    font-size: 96px;
    font-weight: 600;
    text-transform: uppercase;
}

.landing-scroll {
    width: max-content;
    padding: 1rem 2rem;
    position: relative;
    cursor: pointer;
}

.landing-scroll>p {
    margin: 0;
    align-self: center;
    font-size: 18px;
}

.scrolldown {
    position: absolute;
    width: 5rem;
    height: 5rem;
    left: -3rem;
    bottom: -.5rem;
}

.scrolldown-right {
    right: -3rem;
    left: unset;
}

.see-more {
    padding-bottom: .2rem;
}