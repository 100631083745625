.about-section {
    padding: 10rem 0rem;
    box-sizing: border-box;
    background-color: #efefef;
    position: relative;
}

.about-main-cont {
    background: rgb(0, 0, 0);
    background: linear-gradient(135deg, rgb(37, 37, 37) 0%, rgba(135, 136, 136, 0.9360119047619048) 100%);
    margin-top: 7rem;
    height: 100%;
}

.about-inner {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 4rem 1rem;

}

.about-first {
    padding-left: 5rem;
}

.about-second {
    padding-right: 5rem;
}

.about-inner-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: .5rem;
}

.about-container {
    height: 100%;
    width: 100%;

}

.about-c-1 {
    padding: 3rem 5rem;
    box-sizing: border-box;
}

.about-container>h3 {
    margin: 0;
    font-size: xx-large;
    font-weight: 500;
}

.about-container>p {
    font-size: 18px;
}


.about-upper {
    padding: 0 10rem;
    box-sizing: border-box;
    position: absolute;
    justify-content: space-between;

    left: 50%;
    top: 7.5rem;

    transform: translate(-50%, 0);
}

.about-upper>div {
    margin: 0 10rem;
    width: 30rem;
}

.about-upper>div>p {
    margin: 0;
    font-size: 20px;
}


.about-mini-bottom {
    margin-left: 2rem !important;
}


.about-mini-title {
    margin: 0;
    margin-bottom: .1rem;
    font-size: 20px;
    font-weight: 500;
    color: #2c2c2c;
}

.about-mini-bottom {
    color: #2c2c2c;
    opacity: .6;
}


.socials-box>a>img {
    width: 2rem;
}

.socials-box>a {
    opacity: .6;
    margin-right: .5rem;
    transition: .2s ease-in-out;
}

.socials-box>a:hover {
    opacity: 1;
    transition: .2s ease-in-out;
}