.ContactPage {
    background-color: #efefef;
}

.contact-inner {
    min-height: 100vh;
    padding: 0 2rem;
}

.contact-main {
    /* background-color: rgb(185, 185, 185); */

    margin: 0 25rem;
    margin-top: 10rem;
}

.contact-nav,
.contact-nav>div>a {
    color: #2c2c2c;
}

.contact-nav {
    justify-content: space-between;
    padding: 1rem 0;
    padding-right: 5rem;
}

.contact-nav>h2 {
    font-weight: 400;
    font-size: 28px;
    margin: 0;
}

.c-main-inner {
    justify-content: space-between;
    color: #2c2c2c;
    margin-bottom: 2rem;
}

.c-main-inner>p, .c-main-inner>h1 {
    margin: 0;
}

.c-form-inner {
    min-height: 3rem;
    position: relative;
    margin-bottom: 2rem;
}

.c-form-inner>label {
    align-content: center;
    width: 15rem;
    color: #2c2c2c;
}

.c-form-inner>input {
    width: 100%;
    border: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
}

.c-form-inner>select {
    width: 100%;
    border: none;
    background: transparent;
    font-family: "Poppins", sans-serif;
}

.c-form-inner>input:focus,
.c-form-inner>textarea:focus,
.c-form-inner>select:focus {
    outline: none;
    box-shadow: none;
}


.c-form-inner>textarea {
    width: 100%;
    border: none;
    background: transparent;
    height: 5rem;
    resize: none;
    font-family: "Poppins", sans-serif;
}

.textholder-form {
    align-content: start !important;
    margin-top: .5rem;
}

.c-form>button {
    height: 3rem;
    width: 10rem;
    align-self: flex-end;
    font-family: "Poppins", sans-serif;
}


.underline-div {
    border-bottom: 2px solid rgb(221, 221, 221);
}

.underline-div:after {
    width: 0%;
    height: 2px;
    display: block;
    background-color: #3d3d3d;
    content: " ";
    position: absolute;
    bottom: -2px;
    left: 50%;
    -webkit-transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.underline-div:hover:after {
    width: 100%;
    height: 2px;
    display: block;
    background-color: #3d3d3d;
    content: " ";
    position: absolute;
    bottom: -2px;
    left: 0;
}