/* src/components/Menu.css */

/* Styling for the menu button */
.menu-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1001;
    /* Ensure this is higher than other elements */
    background-color: #efefef;
    /* White background for the button */
    border: none;
    border-radius: 50%;
    /* Makes the button circular */
    width: 50px;
    /* Adjust size as needed */
    height: 50px;
    /* Adjust size as needed */
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

/* Base styles for SVG icon lines */
.icon line {
    stroke: black;
    /* Black color for the icon lines */
    stroke-width: 2;
    transition: transform 0.4s ease, opacity 0.4s ease;
}

/* Transform the menu icon into a close (X) icon */
.icon-close .line1 {
    transform: rotate(45deg) translate(0px, 5px);
    transform-origin: center;
    /* Ensures the rotation occurs from the center */
}

.icon-close .line2 {
    opacity: 0;
    /* Hide the middle line */
}

.icon-close .line3 {
    transform: rotate(-45deg) translate(1px, -6px);
    transform-origin: center;
    /* Ensures the rotation occurs from the center */
}

/* Full-page menu container */
.full-page-menu {
    position: fixed;
    /* Positioned relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a212d;
    /* Semi-transparent background */
    display: flex;
    transform: translateX(100%);
    /* Start off-screen to the right */
    transition: transform 0.5s ease;
    /* Smooth transition for sliding */
    z-index: 1000;
    /* Ensure this is higher than other elements */
}

/* When the menu is open, slide it into view */
.full-page-menu.open {
    transform: translateX(0);
}


.menu-title {
    font-size: 36px;
    margin: 0;
    margin: 1rem 2rem;
    font-weight: 500;
    color: #efefef;
}


.menu-link {
    font-size: 72px;
    text-decoration: none;
    position: relative;
    margin: 0;
    margin-bottom: 1rem;
    color: #667488;
    transition: .4s ease-in-out;
}

.menu-link:hover {
    color: #efefef;
    transition: .4s ease-in-out;
}

.menu-inner {
    padding: 5rem 10rem;
    justify-content: space-between;
}

.link-underline {
    border-bottom: 2px solid transparent;
    width: max-content;
}

.link-underline:after {
    width: 0%;
    height: 2px;
    display: block;
    background-color: #efefef;
    content: " ";
    position: absolute;
    bottom: -2px;
    left: 50%;
    -webkit-transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.link-underline:hover:after {
    width: 100%;
    height: 2px;
    display: block;
    background-color: #efefef;
    content: " ";
    position: absolute;
    bottom: -2px;
    left: 0;
}



.menu-second {
    width: 20rem;
}

.menu-second>hr {
    width: 100%;

    border: none;
    border-bottom: 1px solid #66748870;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.menu-s>h4 {
    color: #667488;
    font-weight: 500;
    font-size: 20px;
    margin: 0;
    margin-bottom: 1rem;
}

.menu-s-inner>a {
    text-decoration: none;
    color: #efefefbd;
    margin: 0;
    margin-bottom: .5rem;
    transition: .2s ease-in-out;
}

.menu-s-inner>p>a {
    text-decoration: none;
    color: #efefefbd;
    transition: .2s ease-in-out;
}

.menu-s-inner>a:hover {
    color: #efefef;
    transition: .2s ease-in-out;
}

.menu-s-inner>p>a:hover {
    color: #efefef;
    transition: .2s ease-in-out;
}

.menu-s-inner>p {
    color: #667488;
    margin: 0;
}

.menu-s-inner>p>span {
    margin: 0;
    margin-left: .5rem;
    margin-right: .5rem;
}