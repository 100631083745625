body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #efefef;
  background-color: #efefef;
}

.flex {
  display: flex;
}

.flexRow {
  flex-direction: row;
}

.flexColumn {
  flex-direction: column;
}

.justify {
  justify-content: space-between;
}

a {
  cursor: pointer;
}

video {
  pointer-events: none;
  user-select: none;
}