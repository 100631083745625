/* src/FullPageScroll.css */
.fullpage-container {
    height: 100vh;
    overflow: hidden;
  }
  
  .fullpage-section {
    height: 100vh;
    display: flex;
    color: white;
    overflow: hidden;
  }
  