.showcase-container {
    background-color: #efefef;
    color: #2c2c2c;
    font-weight: 400;
}

.showcase-outer {
    height: 100vh;
    justify-content: center;
    padding: 0 10rem;
}

.showcase-inner {
    justify-content: space-between;
    margin-bottom: 2rem;
}

.showcase-smalltitle {
    width: max-content;
    font-weight: 500;
    font-style: italic;
    letter-spacing: .2rem;
    opacity: .8;
    align-content: end;
}

.showcase-bigtitle {
    width: 50%;
    font-weight: 400;
    font-size: 22px;

}

.test {
    height: 20vh !important;
}

.inner-fullpage-section {
    height: 50vh;
}

.showcase-buttons {
    align-self: flex-end;
    margin-top: 1rem;
}


.showcase-button {
    display: inline-block;
    vertical-align: top;

    height: 34px;
    line-height: 36px;
    text-transform: uppercase;
    text-decoration: none;
    color: #3d3d3d;
    letter-spacing: 0.1em;
    text-align: center;
    font-size: 1.2rem;
    margin: 10px;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    margin-left: 2rem;
}

.showcase-button span {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

.underline {
    border: 2px solid transparent;
}

.underline:after {
    width: 0%;
    height: 2px;
    display: block;
    background-color: #3d3d3d;
    content: " ";
    position: absolute;
    top: 34px;
    left: 50%;
    -webkit-transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: left 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.underline:hover:after {
    width: 100%;
    height: 2px;
    display: block;
    background-color: #3d3d3d;
    content: " ";
    position: absolute;
    top: 34px;
    left: 0;
}

/*  */

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #efefef;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #3d3d3d;
    background-color: #efefef;
    border-radius: 5rem;
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: #3d3d3d;
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}

.swiper-pagination {
    width: fit-content !important;
    background-color: #efefef;
    border-radius: 1rem;
    padding: 0 .5rem;
    box-sizing: border-box;
    margin: 1rem;
    top: 0 !important;
    bottom: unset !important;
    box-shadow: #3d3d3d 0px 2px 5px 0px;
}

.swiper-pagination-bullet {
    background: #3d3d3d !important;
    margin: auto .5 !important;
}

.swiper-pagination-bullet-active {
    background: #3d3d3d !important;
}

.swiperGrid {
    height: inherit;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    gap: 16px;
}

/* Inner grid for column layout */
.swiperGridInner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* Space between inner elements */
    width: 41%;
}

/* Image container */
.swiperImgContainer {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Image styling */
.swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.swiperGridWarp>.swiperImgContainer {
    flex: 1 1 calc(50% - 16px);
    aspect-ratio: 1;
    height: calc(50% - 8px);
}


.swiper-container-full {
    width: 100%;
}

.swiper-img-full-1 {
    width: unset;
    object-position: 0 75%;
}

.swiperGridWarp {
    flex-wrap: wrap;
    gap: 16px;
}

.swiper-img {
    border-radius: .5rem;
}