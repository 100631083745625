.footer-section {
    background: linear-gradient(135deg, #272727 0%, #181818 100%);
    padding: 5rem 5rem;
    box-sizing: border-box;
    color: #efefef;

    justify-content: space-between;
}

.hr-cont {
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.hr-p {
    text-wrap: nowrap;
    word-break: keep-all;
    width: max-content;
    margin: 0 2rem;
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    color: #efefef;

    opacity: .5;
}

.footer-hr {
    border: none;

    border-top: 3px double #efefef;
    width: 100%;
    align-self: center;

    opacity: .5;
}

.footer-upper {
    justify-content: space-between;
}

.footer-contact-info>p {
    font-size: 48px;
    font-weight: 600;
    margin: 0;
}

.footer-contact-inner {
    margin-top: 2rem;
}

.footer-contact-inner>a {
    margin: 0;
    text-decoration: none;
    color: #efefef8a;
    margin-top: .75rem;
    transition: .2s ease-in-out;
}

.footer-contact-inner>a:hover {
    color: #efefef;
    transition: .2s ease-in-out;
}

.follow-us-title {
    margin-bottom: 1.25rem;
    font-size: 22px;
}

.footer-socials>a {
    text-decoration: none;
    color: #efefef8a;
    margin-top: .75rem;
    transition: .2s ease-in-out;
}

.footer-socials>a:hover {
    color: #efefef;
    transition: .2s ease-in-out;
}


.footer-bottom {
    justify-content: space-between;
}

.footer-contact {
    width: 50%;
}

.footer-links {
    width: 40%;
    justify-content: flex-end;
}

.footer-disclaimer {
    margin-left: 10rem;
}


.footer-bottom-title {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    margin-bottom: .5rem;
}


.footer-bottom-link {
    text-decoration: none;
    color: #efefef8a;
    margin-top: .75rem;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.footer-bottom-link:hover {
    color: #efefef;
}

.footer-bottom-normal {
    color: #efefefe1;
    margin: 0;
    margin-top: .75rem;
}

.highlighted-span {
    color: #efefef;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.footer-bottom-link>img {
    width: 2rem;
}

.footer-socials-inner>a {
    margin: 0;
    margin-top: .75rem;
    margin-right: .5rem;

    transition: .2s ease-in-out;

}

.footer-socials-inner>a>img {
    filter: invert(1);
    opacity: .3;

    transition: .2s ease-in-out;
}


.footer-socials-inner>a:hover img {
    filter: invert(1);
    opacity: 1;

    transition: .2s ease-in-out;
}

.footer-socials {
    margin-right: 5rem;
}

.map-iframe {
    filter: brightness(.7);
    border-radius: .5rem;
}

.footer-socials-inner {
    flex-wrap: wrap;
    width: 7rem;
}


.phone-numbers>a {
    margin: 0;
    text-decoration: none;
    color: #efefef8a;
    margin-top: .75rem;
    transition: .2s ease-in-out;
}

.phone-numbers>a:hover {
    color: #efefef;
    transition: .2s ease-in-out;
}

.phone-numbers>p {
    margin: 0;
    margin-left: .5rem;
    margin-right: .5rem;
    align-content: end;
}

.working-hours {
    margin-top: 2rem;
    margin-bottom: 0;
}

.wh-day {
    color: #efefefbe;
}

.wh-middle {
    color: #efefef2f;
    margin-left: .5rem;
    margin-right: .5rem;
}

.ecg {
    font-weight: 600;
    color: #fff;
}

.media {
    color: #fff;
}