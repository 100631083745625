.ServicesPage {
    background-color: #efefef;
}

.services-inner {
    min-height: 100vh;
    padding: 5rem 0;
}

.offers {
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(135deg, rgb(20, 20, 20) 0%, rgba(88, 88, 88, 0.936) 100%);
    height: 20rem;
    padding: 0 20rem;
    box-sizing: border-box;
    align-items: center;
}

.offers>h3 {
    font-size: 28px;
    font-weight: 600;
}


.offers-inner {
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;
}

.offers-inner>div>img {
    width: 5rem;
    align-self: center;
}

.offers-inner>div>p {
    font-size: 20px;
    text-align: center;
}

.testimonials {
    width: 100%;
    color: #2c2c2c;
    align-items: center;
    margin-top: 4rem;
}

.testimonials>h3 {
    font-size: 28px;
    font-weight: 600;
}

.testimonials-inner {
    margin-top: 2rem;
}

.tm {
    /* background-color: blue; */
    width: 20rem;
    margin-right: 2rem;
    margin-left: 2rem;
}

.tm-name {
    margin: 0;
    font-weight: 500;
    font-size: 22px;
    margin-bottom: .5rem;
}

.tm-de {
    margin: 0;
    margin-bottom: 1rem;
}

.tm-s>img {
    margin-right: .2rem;
    width: 2rem;
}

.tm-n {
    opacity: .3;
}

.tm-o {
    margin-top: 8rem;
    width: 70%;
    text-align: center;
    font-style: italic;
}