.loading-screen {
    position: absolute;
}

.phase {
    position: absolute;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.phase-one {
    background-color: rgb(39, 39, 39);
    z-index: 5000;
}


.l1,
.l2 {
    text-align: center;
    margin: .5rem;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 400;

}

.l2 {
    font-weight: 500;
}

.l1-1,
.l2-1 {
    opacity: 0;
}


.phase-two {
    background-color: rgb(26, 26, 26);

}

.l2-2 {
    opacity: 0;
}


.phase-three {
    background-color: rgb(0, 0, 0);
}

.l2-3 {
    opacity: 1;
}


.phase1active {
    clip-path: circle(0% at 0% 0%);
    animation: phaseOneExpand 1.5s forwards ease-in-out;
    z-index: 5001;
}

@keyframes phaseOneExpand {
    0% {
        clip-path: circle(0% at 0% 0%);
    }

    100% {
        clip-path: circle(150% at 50% 50%);
    }
}


.phase2active {
    clip-path: circle(0% at 100% 100%);
    animation: phaseTwoExpand 1.5s forwards ease-in-out;
    z-index: 5002;
}


.hide {
    display: none;
}

@keyframes phaseTwoExpand {
    0% {
        clip-path: circle(0% at 100% 100%);
    }

    100% {
        clip-path: circle(150% at 50% 50%);
    }
}


.ended {
    animation: shrinkAway 1.5s forwards ease-in-out;
}

@keyframes shrinkAway {
    0% {
        clip-path: circle(150% at 50% 50%);
    }

    100% {
        clip-path: circle(0% at 50% 50%);
    }
}