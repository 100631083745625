.interior-section {
    padding: 7rem 15rem;
    box-sizing: border-box;
    background-color: #efefef;
    position: relative;
}


.interior-upper {
    height: 100%;
    color: #2c2c2c;
    justify-content: space-between;
}

.interior-bottom {
    width: 100%;
    height: 80%;
}

.interior-bottom > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 90%;
    border-radius: .5rem;
}

.interior-bigtitle {
    width: 60%;
}

.interior-smalltitle {
    text-align: end;
    align-self: flex-end;
}